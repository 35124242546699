import styled from "styled-components";
export const StyledVideoWrapper = styled.div`
  .videoAspectRatio {
    position: relative;
    padding-bottom: 56.2%;
  }
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export default StyledVideoWrapper;

import React from "react";

import StyledVideoWrapper from "../shared/StyledVideoWrapper";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";

const ApproachGraphQLRest = () => {
  return (
    <StyledCommonTabPanelWrapper id="hasura-approach-to-graphql-rest-panel">
      <StyledSubTitle2 paddingBottom="pb32">Hasura’s Approach to GraphQL AND REST</StyledSubTitle2>
      <div className="twoColumGrid">
        <StyledDesc2>
          With Hasura 2.0, Hasura now works with both GraphQL and REST.<br/><br/>
          You can read more about it in this <a href="https://hasura.io/blog/announcing-hasura-graphql-engine-2-0/" target="_blank" rel="noopener noreferrer">article where we announced Hasura 2.0</a>. We also have an article on <a href="https://hasura.io/blog/a-hasura-2-0-engineering-overview/" target="_blank" rel="noopener noreferrer">an engineering overview of Hasura 2.0</a> - basically how we added support for REST APIs.<br/><br/>
          Also, <a href="https://hasura.io/blog/rest-view-of-graphql/" target="_blank" rel="noopener noreferrer">read our blog post on the architectural differences between REST and GraphQL</a>.
        </StyledDesc2>
        <div>
          <StyledVideoWrapper>
            <div className="videoAspectRatio">
              <iframe
                loading="lazy"
                title="Watch Tanmai’s talk introducing GraphQL"
                src="https://player.vimeo.com/video/499145094"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </StyledVideoWrapper>
          <StyledDesc2 className="italic" variant="grey_80" fontWeight="font_500">Watch Tanmai Gopal, CEO of Hasura, introducing GraphQL</StyledDesc2>
        </div>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default ApproachGraphQLRest;

import React from "react";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

import graphqlMicroservice from "./images/microservice-serverless.gif";

const GraphQLMicroservices = () => {
  return (
    <StyledCommonTabPanelWrapper id="graphql-api-microservices-panel">
      <div className="twoColumGrid">
        <div>
          <StyledSubTitle2 paddingBottom="pb8">GraphQL API for your microservices:</StyledSubTitle2>
          <StyledDesc1 fontWeight="font_600" paddingBottom="pb32">Using Hasura Remote Schemas & Remote Joins</StyledDesc1>
          <StyledDesc2>
            Hasura can connect to databases, upstream “mid-tier” microservices, external SaaS APIs, and event-driven business logic to expose a unified GraphQL API.<br/><br/>
            Hasura is designed to integrate with the emerging cloud-native patterns for building services. The future of business logic workflows in APIs or serverless functions is going to be event-driven, and Hasura makes that easy. Hasura provides an authorization engine that allows for securing the unified GraphQL API, including a powerful role, attribute, and rule-based access control.
          </StyledDesc2>
        </div>
        <div className="panelImg">
          <img src={graphqlMicroservice} alt="GraphQL API for your microservices" />
        </div>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default GraphQLMicroservices;

import React from "react";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";

const EventsComponent = ({currentPanel, imgSrc}) => {
  return (
    <StyledCommonTabPanelWrapper id={currentPanel.id}>
      <div className="twoColumGrid">
        <div>
          <StyledSubTitle2 paddingBottom="pb8">{currentPanel.title}</StyledSubTitle2>
          <StyledDesc2>{currentPanel.desc}</StyledDesc2>
        </div>
        <div className="panelImg">
          <img src={imgSrc} alt={currentPanel.title} />
        </div>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default EventsComponent;

import styled from "styled-components";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { transition } from "./CommonStyled";

export const StyledDBIconWrapper = styled.div`
  .dbIconWrapper {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    padding-bottom: 80px;
    .dbBrandList {
      background-color: ${COLORS.white};
      box-shadow: ${ELEVATION.l_0};
      border-radius: 4px;
      ${transition};
      &:hover {
        box-shadow: ${ELEVATION.l_3};
      }
      .dbBrandImg {
        padding: 14px;
        text-align: center;
        img {
          max-height: 64px;
          display: inline-block;
        }
      }
      .dbBrandTxt {
        background-color: ${COLORS.grey_4};
        border-radius: 0px 0px 4px 4px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ml-6 {
          margin-left: 6px;
        }
      }
    }
  }
  ${mq.between("lg", "xl")} {
    .dbIconWrapper {
      .dbBrandList {
        .dbBrandTxt {
          .mobileFontSize {
            font-size: 13px;
          }
        }
      }
    }
  }
  ${mq.between("md", "lg")} {
    .dbIconWrapper {
      width: 100%;
      grid-gap: 24px;
      .dbBrandList {
        .dbBrandTxt {
          padding: 6px;
          .mobileFontSize {
            font-size: 12px;
          }
        }
      }
    }
  }
  ${mq.below.md} {
    overflow: auto;
    .dbIconWrapper {
      width: 790px;
      grid-gap: 24px;
      padding: 10px 10px;
      padding-bottom: 50px;
      .dbBrandList {
        .dbBrandTxt {
          padding: 6px;
          .mobileFontSize {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
export default StyledDBIconWrapper;

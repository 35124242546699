import React from "react";

import StyledVideoWrapper from "../shared/StyledVideoWrapper";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

const FiveYearsGraphQL = () => {
  return (
    <StyledCommonTabPanelWrapper id="5-years-of-graphql-panel">
      <div className="twoColumGrid">
        <div>
          <StyledSubTitle2 paddingBottom="pb8">5 Years of GraphQL</StyledSubTitle2>
          <StyledDesc2 paddingBottom="pb32">
            2020 represents 5 years on GraphQL! We asked some of GraphQL’s earliest adopters 10 questions about GraphQL & its evolution. Watch the video to know more!
          </StyledDesc2>
          <StyledDesc1 className="italic" fontWeight="font_500" paddingBottom="pb8">“GraphQL wouldn’t exist without the community of engineers who continue to contribute to making GraphQL what it is today.”</StyledDesc1>
          <StyledDesc2>Lee Byron, Co-Creator, GraphQL</StyledDesc2>
        </div>
        <div>
          <StyledVideoWrapper>
            <div className="videoAspectRatio">
              <iframe
                loading="lazy"
                title="Watch Tanmai’s talk introducing GraphQL"
                src="https://www.youtube.com/embed/s8meG38iZAM"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </StyledVideoWrapper>
          <StyledDesc2 className="italic" variant="grey_80" fontWeight="font_500">Five years of GraphQL 🎉</StyledDesc2>
        </div>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default FiveYearsGraphQL;

import React from "react";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

import graphqlServerless from "./images/graphql-serverless.svg";

const GraphQLServerless = () => {
  return (
    <StyledCommonTabPanelWrapper id="graphql-serverless-panel">
      <div className="twoColumGrid">
        <div>
          <StyledSubTitle2 paddingBottom="pb8">GraphQL with Serverless:</StyledSubTitle2>
          <StyledDesc1 fontWeight="font_600" paddingBottom="pb32">Hasura serves as a data layer for serverless applications</StyledDesc1>
          <StyledDesc2>
            In Hasura, business logic workflows in APIs or serverless functions are event-driven. Hasura also provides a way to create custom GraphQL mutations that are internally converted to events and delivered to serverless functions in an event-driven way. This makes it possible to integrate and migrate to event-driven business logic seamlessly.<br/><br/>
            Hasura Cloud gives you a scalable, highly available, globally distributed, secure GraphQL API over your data sources.
          </StyledDesc2>
        </div>
        <div className="panelImg">
          <img src={graphqlServerless} alt="GraphQL with Serverless" />
        </div>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default GraphQLServerless;

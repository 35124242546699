import React from "react";
import styled from "styled-components";

import { StyledSubTitle1 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";
import { asc } from "../shared/CommonStyled";

const StyledHeaderBannerWrapper = styled.div`
  padding-bottom: 24px;
  .headerBannerWrapper {
    display: flex;
    align-items: center;
    .headerImg {
      padding-right: 20px;
    }
    .flexOne {
      flex: 1;
    }
  }
  ${mq.below.md} {
    padding-bottom: 24px;
    .headerBannerWrapper {
      .headerImg {
        padding-right: 12px;
      }
    }
  }
`;

const HeaderBanner = ({title, imgSrc, bgClass}) => {
  return (
    <StyledHeaderBannerWrapper>
      <div className={"headerBannerWrapper " + ((bgClass) ? [bgClass] : "")}>
        {
          imgSrc ? (
            <div className="headerImg">
              <img src={imgSrc} alt={title} />
            </div>
          ) : null
        }
        <StyledSubTitle1 className="flexOne" css={asc}>{title}</StyledSubTitle1>
      </div>
    </StyledHeaderBannerWrapper>
  );
};

export default HeaderBanner;

import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc1, StyledDesc2, StyledDescCode14 } from "../shared/StyledTypography";

const queryDemo = `Query {
  authors { # fetches authors (1 query)
    name
    articles { # fetches articles for each author
      title # (N queries for N) authors
    }
  }
}`;

const GraphQLServer = () => {
  return (
    <StyledCommonTabPanelWrapper id="graphql-server-panel">
      <StyledSubTitle2 paddingBottom="pb32">GraphQL Server</StyledSubTitle2>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">Introduction</StyledDesc1>
      <StyledDesc2 paddingBottom="pb40">
        To serve a GraphQL API to your client, you need a GraphQL server. Hasura is a web application server that instantly generates GraphQL APIs out of the box, such that you don’t need to build a GraphQL server.<br/><br/>
        Hasura auto generates a GraphQL API on your databases & services and lets you extend the graph using <a href="https://hasura.io/docs/latest/graphql/core/actions/index.html" target="_blank" rel="noopener noreferrer">Hasura Actions</a>.
        You can bring in existing GraphQL servers and stitch them together with Hasura using the <a href="https://hasura.io/docs/latest/graphql/core/remote-schemas/index.html" target="_blank" rel="noopener noreferrer">Remote Schemas</a> feature, and join data across your database & services using <a href="https://hasura.io/blog/remote-joins-a-graphql-api-to-join-database-and-other-data-sources/" target="_blank" rel="noopener noreferrer">Remote Joins</a>.
      </StyledDesc2>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">Hasura as a high-performance GraphQL Server</StyledDesc1>
      <StyledDesc2 paddingBottom="pb40">
        Hasura compiles a GraphQL query of any length to a single SQL query. Internally Hasura enriches the GraphQL AST with user-given permissions & maps rules to create an SQL AST.<br/><br/>
        Hasura also caches your GraphQL query plan and makes highly optimized database calls. It also provides out-of-the-box GraphQL subscriptions that can be consumed at scale.
      </StyledDesc2>
      <div className="twoColumGrid">
        <div>
          <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">What is the N+1 Problem with GraphQL</StyledDesc1>
          <StyledDesc2>
            GraphQL query execution typically involves executing a resolver for each field. Say we're fetching authors and their articles. Now for each author record in the database, we would invoke a function to fetch their N articles, So total round trips become N+1 and this could become a huge performance bottleneck. The number of queries grows exponentially with the depth of the query.<br/><br/>
            Hasura mitigates the N+1 problem as at its core, the server is a compiler. All your GraphQL queries to fetch data are compiled to a single SQL query that reduces the number of hits to the database, thereby improving performance.<br/><br/>
            <a href="https://hasura.io/blog/architecture-of-a-high-performance-graphql-to-sql-server-58d9944b8a87/" target="_blank" rel="noopener noreferrer">How Hasura solves the GraphQL N+1 Problem <div className="arrow">{">"}</div></a>
          </StyledDesc2>
        </div>
        <StyledDescCode14>
          <div className="codeBlockContainer">
            <CopyBlock
              language="graphql"
              text={queryDemo}
              showLineNumbers={false}
              theme={dracula}
              wrapLines={true}
              codeBlock
            />
          </div>
        </StyledDescCode14>
      </div>
    </StyledCommonTabPanelWrapper>
  );
};

export default GraphQLServer;

import React, { useState } from "react";
import styled from "styled-components";

import { StyledDesc3 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import {showTab, hideTab} from "../shared/CommonStyled";

const StyledCommonTabWrapper = styled.div`
  padding-bottom: 30px;
  .mobileHide {
    display: contents;
  }
  .mobileShow {
    display: none;
  }
  .tabUl {
    border-bottom: 1px solid ${COLORS.grey_20};
    display: flex;
    position: sticky;
    top: 72px;
    z-index: 1;
    background-color: ${COLORS.white};
    li {
      padding-bottom: 12px;
      margin: 12px;
      margin-bottom: -1px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .activeTabList {
      border-bottom: 2px solid ${COLORS.sky_80};
      .tabActiveCont {
        color: ${COLORS.sky_80};
      }
    }
  }
  ${mq.below.md} {
    .mobileHide {
      display: none;
    }
    .mobileShow {
      display: block;
    }
  }
`;
const CommonTabWrapper = ({tabItem}) => {
  const [activeTab, setActiveTab] = useState(tabItem[0].id);
  // const toggleTop = id => {
  //   document.getElementById(id).scrollTo(0, 0);
  // };
  return (
    <StyledCommonTabWrapper>
      <div className="mobileHide">
        <ul className="tabUl">
          {
            tabItem.map((item)=> (
              // eslint-disable-next-line
              <li key={item.id} className={item.id === activeTab ? "activeTabList" : ""} onClick={()=>{setActiveTab(item.id);}}>
                <StyledDesc3 className="tabActiveCont" fontWeight="font_600">
                  {item.title}
                </StyledDesc3>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="mobileHide">
      {
        tabItem.map((item)=> (
          <div key={item.id} css={item.id === activeTab ? showTab : hideTab}>
            {item.content()}
          </div>
        ))
      }
      </div>
      <div className="mobileShow">
        {
          tabItem.map((item)=> (
            <div key={item.id}>
              {item.content()}
            </div>
          ))
        }
      </div>
    </StyledCommonTabWrapper>
  );
};

export default CommonTabWrapper;

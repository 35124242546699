import styled from "styled-components";

import { mq } from "../../globals/utils";

export const StyledHashTagOffsetPos = styled.div`
  &:before {
    content: "";
    display: block;
    visibility: hidden;
    height: 100px;
    margin-top: -100px;
  }

  ${mq.below.md} {
    &:before {
      height: 85px;
      margin-top: -85px;
    }
  }

  .mobile-indiv-talk-card {
    scroll-margin-top: 80px;
    padding-top: 32px;
    /* margin-top: -96px; */
  }

  @media (max-width: 767px) {
    padding-top: 24px;
    scroll-margin-top: 60px;
    /* margin-top: -104px; */
  }
`;

export default StyledHashTagOffsetPos;

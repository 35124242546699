import React from "react";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { removePaddTop } from "../shared/CommonStyled";

const FullWidthComponent = ({currentPanel, removeTopPadd}) => {
  return (
    <StyledCommonTabPanelWrapper id={currentPanel.id} css={removeTopPadd ? removePaddTop : null}>
      {
        currentPanel.title ? (
          <StyledSubTitle2 paddingBottom="pb16">{currentPanel.title}</StyledSubTitle2>
        ) : null
      }
      <StyledDesc2>{currentPanel.desc}</StyledDesc2>
    </StyledCommonTabPanelWrapper>
  );
};

export default FullWidthComponent;

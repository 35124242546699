import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";

import { Image } from "../../globals/UIKit";
import HeaderBanner from "./HeaderBanner";
import CommonTabWrapper from "./CommonTabWrapper";
import FullWidthComponent from "./FullWidthComponent";
import ApproachGraphQLRest from "./ApproachGraphQLRest";
import GraphQLServer from "./GraphQLServer";
import GraphQLClient from "./GraphQLClient";
import GraphQLSpec from "./GraphQLSpec";
import GraphQLMicroservices from "./GraphQLMicroservices";
import GraphQLServerless from "./GraphQLServerless";
import Tutorials from "../learn/Tutorials";
import OpenSourceGraphQL from "./OpenSourceGraphQL";
import EventsComponent from "./EventsComponent";
import FiveYearsGraphQL from "./FiveYearsGraphQL";
import FaqsComponent from "./FaqsComponent";
import UseHasuraFree from "../learn/UseHasuraFree";
import ChevronRight from "../../globals/icons/ChevronRight";
import SideBarToggle from "../../globals/icons/SideBarToggle";
import Close from "../../globals/icons/Close";
import CopyWriterWithSidebar from "../common/CopyWriterWithSidebar";
import StyledHashTagOffsetPos from "../shared/StyledHashTagOffsetPos";

import {
  whatIsGraphQLPanel,
  whyGraphQLPanel,
  hasuraApproachPanel,
  graphQLRestPanel,
  graphQlFederation,
  graphQlDatabases,
  graphQLCachingPanel,
  graphQLSecurityPanel,
  graphQLMonitoringObservabilityPanel,
  graphQLProductionReadyPanel,
  graphqlAsia,
  enterpriseGraphQL,
  graphQLContributorDays,
  graphQLFoundationPanel,
  graphQLCommunityEcosystemPanel,
  graphqlFaqsPanel,
  hasuraFaqsPanel,
} from "./PanelState.js";
import {
  frontendTutorialState,
  fullstackTutorialState,
  databaseTutorialState,
  introGraphQLState,
} from "../learn/learnState.js";
import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import {
  StyledMainWrapper,
  StyledToggleSideNavWrapper,
  StyledSideBarWrapper,
  StyledSideBarFooter,
  StyledLearnWrapper,
} from "../shared/CommonLearnWrapper";
import { StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import { flexCenter, showTab, hideTab, translateXZero } from "../shared/CommonStyled";

import graphqlImg from "./images/graphql.svg";
import introductionImg from "./images/introduction.svg";
import breakingDownImg from "./images/breaking-down.svg";
import architectureImg from "./images/architecture.svg";
import federationImg from "./images/federation.svg";
import databaseImg from "./images/database.svg";
import productionImg from "./images/production.svg";
import tutorialImg from "./images/tutorial.svg";
import openSourceImg from "./images/open-source.svg";
import eventImg from "./images/event.svg";
import faqImg from "./images/faq.svg";
import graphqlAsiaPanelImg from "./images/graphql-asia.svg";
import enterpriseGrapQLPanelImg from "./images/enterprise-grapql.svg";
import graphqlContributorPanelImg from "./images/graphql-contributor.svg";
// import Paperform from "../contactus/Paperform";

const overviewGraphQLTab = [
  {
    content: () => <FullWidthComponent currentPanel={whatIsGraphQLPanel} />,
    title: "What is GraphQL",
    id: "waht-is-graphql",
    panelId: whatIsGraphQLPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={whyGraphQLPanel} />,
    title: "Why GraphQL",
    id: "why-graphql",
    panelId: whyGraphQLPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={hasuraApproachPanel} />,
    title: "Hasura’s approach to GraphQL",
    id: "hasura-approach-to-graphql",
    panelId: hasuraApproachPanel.id,
  },
];

const introductionGraphQLTab = [
  {
    content: () => <FullWidthComponent currentPanel={graphQLRestPanel} />,
    title: "GraphQL vs. REST",
    id: "graphql-rest",
    panelId: graphQLRestPanel.id,
  },
  {
    content: () => <ApproachGraphQLRest />,
    title: "Hasura’s approach to GraphQL & REST",
    id: "hasura-approach-to-graphql-rest",
    panelId: "hasura-approach-to-graphql-rest-panel",
  },
];

const breakingDownGraphQLTab = [
  {
    content: () => <GraphQLServer />,
    title: "GraphQL Server",
    id: "graphql-server",
    panelId: "graphql-server-panel",
  },
  {
    content: () => <GraphQLClient />,
    title: "GraphQL Client",
    id: "graphql-client",
    panelId: "graphql-client-panel",
  },
  {
    content: () => <GraphQLSpec />,
    title: "GraphQL Spec",
    id: "graphql-spec",
    panelId: "graphql-spec-panel",
  },
];

const architectureGraphQLTab = [
  {
    content: () => <GraphQLMicroservices />,
    title: "GraphQL API for your microservices",
    id: "graphql-api-microservices",
    panelId: "graphql-api-microservices-panel",
  },
  {
    content: () => <GraphQLServerless />,
    title: "GraphQL with Serverless",
    id: "graphql-serverless",
    panelId: "graphql-serverless-panel",
  },
];

const graphqlProductionTab = [
  {
    content: () => <FullWidthComponent currentPanel={graphQLCachingPanel} />,
    title: "GraphQL Caching",
    id: "graphql-caching",
    panelId: graphQLCachingPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={graphQLSecurityPanel} />,
    title: "GraphQL Security",
    id: "graphql-security",
    panelId: graphQLSecurityPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={graphQLMonitoringObservabilityPanel} />,
    title: "GraphQL Monitoring and Observability",
    id: "graphql-monitoring-observability",
    panelId: graphQLMonitoringObservabilityPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={graphQLProductionReadyPanel} />,
    title: "Making Existing GraphQL APIs Production-Ready",
    id: "graphql-production-ready",
    panelId: graphQLProductionReadyPanel.id,
  },
];

const graphqlEventsTab = [
  {
    content: () => <EventsComponent currentPanel={graphqlAsia} imgSrc={graphqlAsiaPanelImg} />,
    title: "GraphQL Asia",
    id: "graphql-asia",
    panelId: graphqlAsia.id,
  },
  {
    content: () => (
      <EventsComponent currentPanel={enterpriseGraphQL} imgSrc={enterpriseGrapQLPanelImg} />
    ),
    title: "The Enterprise GraphQL Conference",
    id: "enterprise-graphql",
    panelId: enterpriseGraphQL.id,
  },
  {
    content: () => (
      <EventsComponent currentPanel={graphQLContributorDays} imgSrc={graphqlContributorPanelImg} />
    ),
    title: "GraphQL Contributor Days",
    id: "graphql-contributor-days",
    panelId: graphQLContributorDays.id,
  },
];

const graphQLCommunityTab = [
  {
    content: () => <Tutorials tutorialList={graphQLCommunityEcosystemPanel} graphqlPage={true} />,
    title: "GraphQL Community & Ecosystem",
    id: "graphql-community-ccosystem",
    panelId: graphQLCommunityEcosystemPanel.id,
  },
  {
    content: () => <FullWidthComponent currentPanel={graphQLFoundationPanel} />,
    title: "The GraphQL Foundation",
    id: "graphql-foundation",
    panelId: graphQLFoundationPanel.id,
  },
  {
    content: () => <FiveYearsGraphQL currentPanel={graphQLContributorDays} />,
    title: "5 Years of GraphQL",
    id: "5-years-of-graphql",
    panelId: graphQLContributorDays.id,
  },
];

const faqsTab = [
  {
    content: () => (
      <StyledCommonTabPanelWrapper id="graphql-faqs-panel">
        <FaqsComponent faqListItem={graphqlFaqsPanel} title="GraphQL FAQs" />
      </StyledCommonTabPanelWrapper>
    ),
    title: "GraphQL FAQs",
    id: "graphql-faqs",
    panelId: "graphql-faqs-panel",
  },
  {
    content: () => (
      <StyledCommonTabPanelWrapper id="hasura-faqs-panel">
        <FaqsComponent faqListItem={hasuraFaqsPanel} title="Hasura FAQs" />
      </StyledCommonTabPanelWrapper>
    ),
    title: "Hasura FAQs",
    id: "hasura-faqs",
    panelId: "hasura-faqs-panel",
  },
];

const sideBarState = [
  {
    title: "An overview of GraphQL",
    listId: "overview-graphql-nav",
    id: "overview-graphql",
  },
  {
    title: "An introduction to GraphQL",
    listId: "introduction-graphql-nav",
    id: "introduction-graphql",
  },
  {
    title: "Breaking down GraphQL",
    listId: "breaking-down-graphql-nav",
    id: "breaking-down-graphql",
  },
  {
    title: "Application architecture with GraphQL and Hasura",
    listId: "architecture-graphql-nav",
    id: "architecture-graphql",
  },
  {
    title: "GraphQL Federation",
    listId: "database-tutorials-nav",
    id: "graphql-federation",
  },
  {
    title: "GraphQL for Databases",
    listId: "graphql-databases-nav",
    id: "graphql-databases",
  },
  {
    title: "GraphQL in Production",
    listId: "graphql-production-nav",
    id: "graphql-production",
  },
  {
    title: "Tutorials",
    listId: "graphql-tutorials-nav",
    id: "graphql-tutorials",
  },
  {
    title: "Community Open source projects",
    listId: "community-oss-nav",
    id: "community-oss",
  },
  {
    title: "Events, workshops & meetups",
    listId: "events-workshops-meetups-nav",
    id: "events-workshops-meetups",
  },
  {
    title: "GraphQL community",
    listId: "graphql-community-nav",
    id: "graphql-community",
  },
  {
    title: "FAQs",
    listId: "faqs-nav",
    id: "faqs",
  },
];

const StyledSideBarFooterRemoveBor = styled(StyledSideBarFooter)`
  border-top: 0;
`;

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const GraphQLIndex = ({ location }) => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalSideBarSubscribe, setIsLocalSideBarSubscribe] = useState(false);

  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("sideBarSubscribeConsent", "true");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchAliId = searchParams.get("aliId");
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const sideBarSubscribeConsent = window.localStorage.getItem("sideBarSubscribeConsent");
        if (sideBarSubscribeConsent) {
          setIsLocalSideBarSubscribe(true);
        }
      }
    }
  }, [location.search]);

  const headerRef = useRef(null);
  const overviewGraphQLRef = useRef(null);
  const introductionGraphQLRef = useRef(null);
  const breakingDownGraphQLRef = useRef(null);
  const architectureGraphQLRef = useRef(null);
  const graphqlFederationRef = useRef(null);
  const graphqlDatabasesRef = useRef(null);
  const graphqlProductionRef = useRef(null);
  const graphQLTutorialsRef = useRef(null);
  const communityOSSRef = useRef(null);
  const eventsWorkshopsMeetupsRef = useRef(null);
  const graphqlCommunityRef = useRef(null);
  const faqsRef = useRef(null);

  // eslint-disable-next-line
  const sideBarSection = {
    "overview-graphql": { section: "overview-graphql", ref: overviewGraphQLRef },
    "introduction-graphql": { section: "introduction-graphql", ref: introductionGraphQLRef },
    "breaking-down-graphql": { section: "breaking-down-graphql", ref: breakingDownGraphQLRef },
    "architecture-graphql": { section: "architecture-graphql", ref: architectureGraphQLRef },
    "graphql-federation": { section: "graphql-federation", ref: graphqlFederationRef },
    "graphql-databases": { section: "graphql-databases", ref: graphqlDatabasesRef },
    "graphql-production": { section: "graphql-production", ref: graphqlProductionRef },
    "graphql-tutorials": { section: "graphql-tutorials", ref: graphQLTutorialsRef },
    "community-oss": { section: "community-oss", ref: communityOSSRef },
    "events-workshops-meetups": {
      section: "events-workshops-meetups",
      ref: eventsWorkshopsMeetupsRef,
    },
    "graphql-community": { section: "graphql-community", ref: graphqlCommunityRef },
    faqs: { section: "faqs", ref: faqsRef },
  };

  const [visibleSection, setVisibleSection] = useState();
  const [isSubNavShow, setIsSubNavShow] = useState(false);
  const [isLearn, setIsLearn] = useState(true);
  const [openSubNavIds, setOpenSubNavIds] = useState({});

  const showSubNav = id => {
    const newOpenSubNavIds = { ...openSubNavIds };
    if (typeof newOpenSubNavIds[id] === "undefined") {
      newOpenSubNavIds[id] = true;
    } else {
      newOpenSubNavIds[id] = !newOpenSubNavIds[id];
    }
    setOpenSubNavIds(newOpenSubNavIds);
  };

  const sectionRefs = useMemo(() => sideBarSection, [sideBarSection]);

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = Object.values(sectionRefs).find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        } else {
          return null;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, sectionRefs]);

  const sideNav = sideBarState.map(navItem => (
    <li
      id={navItem.listId}
      key={navItem.listId}
      role="presentation"
      onClick={() => {
        scrollTo(sectionRefs[navItem.id].ref.current);
        setIsSubNavShow(false);
      }}
    >
      <StyledDesc3 linkVariant="grey_100">
        <a
          href={`#${navItem.id}`}
          role="button"
          tabIndex="0"
          onClick={() => showSubNav(navItem.listId)}
          className={visibleSection === navItem.id ? "tabListActive" : ""}
        >
          <div className="circle"></div>
          {navItem.title}
        </a>
      </StyledDesc3>
    </li>
  ));

  return (
    <StyledMainWrapper>
      <div className={"learnMainWrapper" + (toggleSideBar ? " sideBarCollapse" : "")}>
        <StyledSideBarWrapper>
          <div
            className={"sidebarWrapper" + (toggleSideBar ? " sidebarWrapperCollapse" : "")}
            css={isSubNavShow ? translateXZero : null}
          >
            <div
              className={"mainSideBarToggle" + (toggleSideBar ? " mainSideBarTogglePos" : "")}
              role="presentation"
              onClick={() => {
                setToggleSideBar(!toggleSideBar);
              }}
            >
              <SideBarToggle variant="grey_100" size="xs" />
              {toggleSideBar ? (
                <div className="navigation">
                  <StyledDesc3 fontWeight="font_600">Navigation</StyledDesc3>
                </div>
              ) : null}
            </div>
            <div className="alignSelfStart removeExternalLinks" ref={headerRef}>
              {!toggleSideBar ? (
                <>
                  <StyledDesc3
                    className="mainNavHeader"
                    linkVariant="grey_100"
                    fontWeight="font_600"
                    css={flexCenter}
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      className={visibleSection === "overview-graphql" ? "tabListActive" : ""}
                      role="presentation"
                      onClick={() => {
                        setIsLearn(!isLearn);
                        scrollTo(sectionRefs["overview-graphql"].ref.current);
                      }}
                    >
                      <ChevronRight
                        variant="grey_100"
                        size="xs"
                        className={isLearn ? "rotateImg" : ""}
                      />
                      GraphQL Hub
                    </a>
                  </StyledDesc3>
                  <ul className="mainNavWrapper" css={isLearn ? showTab : hideTab}>
                    {sideNav}
                  </ul>
                </>
              ) : null}
            </div>
            {/* {!toggleSideBar ? (
              <StyledSideBarFooterRemoveBor>
                <div className="sideBarNewsletterWrapper">
                  {isAliId && isLocalSideBarSubscribe ? (
                    <StyledDesc3>Thank you for subscribing to the Hasura Newsletter!</StyledDesc3>
                  ) : (
                    <>
                      <StyledDesc3 fontWeight="font_600" paddingBottom="pb12">
                        Sign up for Hasura Newsletter
                      </StyledDesc3>
                      <Paperform
                        isLazy
                        onSubmitCB={onSubmitCB}
                        formId="hf-1079"
                        styleClass="marketoFormWrapper sideBarSubscribeWrapper"
                      />
                    </>
                  )}
                </div>
              </StyledSideBarFooterRemoveBor>
            ) : null} */}
          </div>
        </StyledSideBarWrapper>
        <StyledToggleSideNavWrapper onClick={() => setIsSubNavShow(!isSubNavShow)}>
          {isSubNavShow ? (
            <Close variant="white" size="md" />
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V21C2.25 21.4142 2.58579 21.75 3 21.75H9H21C21.4142 21.75 21.75 21.4142 21.75 21V3C21.75 2.58579 21.4142 2.25 21 2.25H9H3ZM9.75 3.75V20.25H20.25V3.75H9.75ZM8.25 3.75H3.75V20.25H8.25V3.75Z"
                fill="#FFF"
              />
            </svg>
          )}
        </StyledToggleSideNavWrapper>
        <StyledLearnWrapper>
          <div className={"learnAsideWrapper" + (toggleSideBar ? " learnAsideWrapperPos" : "")}>
            <StyledHashTagOffsetPos id="overview-graphql" ref={overviewGraphQLRef}>
              <HeaderBanner title="An overview of GraphQL" imgSrc={graphqlImg} />
              <CommonTabWrapper tabItem={overviewGraphQLTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="introduction-graphql" ref={introductionGraphQLRef}>
              <HeaderBanner title="An introduction to GraphQL" imgSrc={introductionImg} />
              <CommonTabWrapper tabItem={introductionGraphQLTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="breaking-down-graphql" ref={breakingDownGraphQLRef}>
              <HeaderBanner title="Breaking down GraphQL" imgSrc={breakingDownImg} />
              <CommonTabWrapper tabItem={breakingDownGraphQLTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="architecture-graphql" ref={architectureGraphQLRef}>
              <HeaderBanner
                title="Application Architectures with GraphQL and Hasura"
                imgSrc={architectureImg}
              />
              <CommonTabWrapper tabItem={architectureGraphQLTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="graphql-federation" ref={graphqlFederationRef}>
              <HeaderBanner title="GraphQL Federation" imgSrc={federationImg} />
              <FullWidthComponent currentPanel={graphQlFederation} removeTopPadd={true} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="graphql-databases" ref={graphqlDatabasesRef}>
              <HeaderBanner title="GraphQL for Databases" imgSrc={databaseImg} />
              <FullWidthComponent currentPanel={graphQlDatabases} removeTopPadd={true} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="graphql-production" ref={graphqlProductionRef}>
              <HeaderBanner title="GraphQL in Production" imgSrc={productionImg} />
              <CommonTabWrapper tabItem={graphqlProductionTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="graphql-tutorials" ref={graphQLTutorialsRef}>
              <HeaderBanner title="GraphQL Tutorials" imgSrc={tutorialImg} />
              <StyledDesc2>
                We’ve come up with an extensive list of courses and tutorials that will take you
                from the basics all the way to production-ready concepts. We’ve covered a wide array
                of topics on GraphQL, Hasura, and Databases and we’re always adding more. These
                courses are open-sourced and community-maintained.
              </StyledDesc2>
              {/* <Tutorials tutorialList={hasuraTutorialState} graphqlPage={true} /> */}
              <Tutorials tutorialList={frontendTutorialState} graphqlPage={true} />
              <Tutorials tutorialList={fullstackTutorialState} graphqlPage={true} />
              <Tutorials tutorialList={databaseTutorialState} graphqlPage={true} />
              <Tutorials tutorialList={introGraphQLState} graphqlPage={true} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="community-oss" ref={communityOSSRef}>
              <HeaderBanner
                title="Open-Source GraphQL Projects that we love"
                imgSrc={openSourceImg}
              />
              <OpenSourceGraphQL removeTopPadd={true} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="events-workshops-meetups" ref={eventsWorkshopsMeetupsRef}>
              <HeaderBanner title="GraphQL Events" imgSrc={eventImg} />
              <CommonTabWrapper tabItem={graphqlEventsTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="graphql-community" ref={graphqlCommunityRef}>
              <HeaderBanner title="The GraphQL Community" imgSrc={graphqlImg} />
              <CommonTabWrapper tabItem={graphQLCommunityTab} />
            </StyledHashTagOffsetPos>
            <StyledHashTagOffsetPos id="faqs" ref={faqsRef}>
              <HeaderBanner title="FAQs" imgSrc={faqImg} />
              <CommonTabWrapper tabItem={faqsTab} />
            </StyledHashTagOffsetPos>
            <UseHasuraFree />
            <div className="footerImg">
              <Image
                loading="lazy"
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/footer-img.jpg"
                alt="footer illustration"
              />
            </div>
            <CopyWriterWithSidebar learnPage />
          </div>
        </StyledLearnWrapper>
      </div>
    </StyledMainWrapper>
  );
};

export default GraphQLIndex;

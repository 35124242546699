import React from "react";
import styled from "styled-components";

import StyledDBIconWrapper from "../shared/StyledDBIconWrapper";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import { removePaddTop, textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import elmGraphql from "./images/elm-graphql.png";
import codeGenerator from "./images/code-generator.svg";
import graphqlPink from "./images/graphql-pink.png";
import gqless from "./images/gqless.png";
import flutter from "./images/flutter.svg";

const StyledDBIconWrapperFullWidth = styled(StyledDBIconWrapper)`
  padding-top: 40px;
  .dbIconWrapper {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 0px;
    .dbBrandList {
      .dbBrandImg {
        padding: 32px 14px;
      }
    }
  }
  ${mq.below.md} {
    padding-top: 24px;
    .dbIconWrapper {
      grid-template-columns: 1fr;
      padding: 10px 10px;
    }
  }
`;

const userBrandState = [
  {
    imgSrc: elmGraphql,
    altText: 'Elm graphql',
    linkUrl: 'https://github.com/dillonkearns/elm-graphql',
    desc: "Elm graphql",
  },
  {
    imgSrc: codeGenerator,
    altText: 'GraphQL code generator',
    linkUrl: 'https://github.com/dotansimha/graphql-code-generator',
    desc: "GraphQL code generator",
  },
  {
    imgSrc: graphqlPink,
    altText: "GraphQL .Net",
    linkUrl: "https://github.com/graphql-dotnet/graphql-dotnet",
    desc: "GraphQL .Net",
  },
  {
    imgSrc: gqless,
    altText: 'GQLess',
    linkUrl: 'https://github.com/gqless/gqless',
    desc: "GQLess",
  },
  {
    imgSrc: flutter,
    altText: 'Graphql Flutter',
    linkUrl: 'https://github.com/zino-app/graphql-flutter',
    desc: "Graphql Flutter",
  },
];

const OpenSourceGraphQL = ({removeTopPadd}) => {
  const userBrand = userBrandState.map((brandItem, index) => {
    return (
      <a key={index} href={brandItem.linkUrl} target="_blank" rel="noopener noreferrer" className="dbBrandList">
        <div className="dbBrandImg">
          <img src={brandItem.imgSrc} alt={brandItem.altText} />
        </div>
        <div className="dbBrandTxt">
          <StyledDesc3 css={textCenter} className="mobileFontSize" fontWeight="font_bold" variant="grey_90">{brandItem.desc}</StyledDesc3>
          { brandItem.comingSoon ? <StyledDesc3 className="ml-6 mobileFontSize" variant="grey_70">{" "}{ brandItem.comingSoon }</StyledDesc3> : null}

        </div>
      </a>
    );
  });
  return (
    <StyledCommonTabPanelWrapper css={removeTopPadd ? removePaddTop : null}>
      <StyledDesc2>
        Hasura’s GraphQL Engine grew to over 300 million downloads in just two years and a lot of the kudos has to go to the GraphQL community at large - starting with the team at Facebook for developing and open-sourcing GraphQL, but also the many developers around the world who tirelessly contribute to making GraphQL what it is today.<br/><br/>
        We’re listing down some <a href="https://hasura.io/blog/open-source-graphql-projects-we-love/" target="_blank" rel="noopener noreferrer">open-source GraphQL projects that we love</a> and are always looking for more to add to this list. So do get in touch, and let us know!<br/><br/>
        Here are 5 just to get this started:
      </StyledDesc2>
      <StyledDBIconWrapperFullWidth>
        <div className="dbIconWrapper">
          {userBrand}
        </div>
      </StyledDBIconWrapperFullWidth>
    </StyledCommonTabPanelWrapper>
  );
};

export default OpenSourceGraphQL;

import styled from "styled-components";

import { mq } from "../../globals/utils";
import { COLORS, ELEVATION } from "../../globals/designSystem";

export const StyledCommonTabPanelWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 50px;
  &:before {
    content: '';
    display: block;
    visibility: hidden;
    height: 150px;
    margin-top: -150px;
  }
  .panelImg {
    img {
      border-radius: 8px;
      box-shadow: ${ELEVATION.l_0};
    }
  }
  .twoColumGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 32px;
  }
  .italic {
    font-style: italic;
    padding-top: 12px;
  }
  .panelUl {
    li {
      padding: 2px 0;
      img {
        margin-top: 4px;
      }
    }
  }
  .codeBlockContainer {
    background-color: ${COLORS.grey_100};
    border-radius: 8px;
    padding: 20px;
    div {
      background: transparent;
      span {
        background: transparent !important;
      }
      button {
        display: none;
      }
    }
    code {
      background-color: transparent;
      color: inherit;
      white-space: pre-wrap;
      padding-left: 16px !important;
      .react-syntax-highlighter-line-number {
        color: ${COLORS.grey_26} !important;
        text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
      }
    }
    }
  ${mq.below.md} {
    padding-top: 0px;
    padding-bottom: 40px;
    .twoColumGrid {
      grid-template-columns: 1fr;
      grid-gap: 32px;
    }
  }
`;

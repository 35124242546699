import React from "react";

import { StyledCommonTabPanelWrapper } from "./StyledCommonTabPanelWrapper";
import { StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

const GraphQLClient = () => {
  return (
    <StyledCommonTabPanelWrapper id="graphql-client-panel">
      <StyledSubTitle2 paddingBottom="pb32">GraphQL Client</StyledSubTitle2>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">Introduction</StyledDesc1>
      <StyledDesc2 paddingBottom="pb40">
        GraphQL requests can be made using your favorite HTTP clients since the underlying request/response format is usually JSON. But there’s more to a GraphQL client than just making HTTP requests, from caching to making real-time apps easy, Hasura provides support for all GraphQL Clients, including <a href="https://hasura.io/docs/latest/graphql/core/databases/postgres/schema/relay-schema.html" target="_blank" rel="noopener noreferrer">Relay</a> (preview). There’s a rich ecosystem of GraphQL clients.
      </StyledDesc2>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb16">Benefits of GraphQL Clients</StyledDesc1>
      <StyledDesc2 fontWeight="font_600" paddingBottom="pb8">
        GraphQL Subscriptions & websockets:
      </StyledDesc2>
      <StyledDesc2 paddingBottom="pb16">
        Consuming a real-time API over WebSockets usually requires a lot of setup. GraphQL Clients like Apollo, Urql make it easy to set it up.
      </StyledDesc2>
      <StyledDesc2 fontWeight="font_600" paddingBottom="pb8">
        GraphQL Fragments & data co-location:
      </StyledDesc2>
      <StyledDesc2 paddingBottom="pb16">
        Relay uses Fragments to declare data requirements for components and compose data requirements together. All data dependencies live alongside component definitions and make it easier to reason about requirements for UIs.
      </StyledDesc2>
      <StyledDesc2 fontWeight="font_600" paddingBottom="pb8">
        GraphQL Caching:
      </StyledDesc2>
      <StyledDesc2>
        The schema and introspection features of GraphQL enable a client to cache responses on the frontend.<br/><br/>
        <a href="https://github.com/chentsulin/awesome-graphql#clients" target="_blank" rel="noopener noreferrer">Learn more about GraphQL Clients <div className="arrow">{">"}</div></a>
      </StyledDesc2>
    </StyledCommonTabPanelWrapper>
  );
};

export default GraphQLClient;

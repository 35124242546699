import React from "react";
import Layout from "../components/layout";
import GraphQLIndex from "../components/graphql/GraphQLIndex";
import Seo from "../components/seo";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/graphql.png",
};
const canonicalUrl = "https://hasura.io/graphql/";

const Learn = props => (
  <Layout location={props.location}>
    <Seo
      title="A Comprehensive Guide to GraphQL with Hasura"
      description="THE GraphQL Hub for resources & solutions (caching, security, etc.); Hasura supported databases; OSS projects, workshops, meetups & GraphQL Foundation."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <GraphQLIndex location={props.location}/>
  </Layout>
);

export default Learn;
